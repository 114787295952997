<script setup lang="ts">
import { BaseButton } from '@legacy-studio/library';
import {
	useChangeChartType,
	useChartType,
	type ChartType,
} from '@/src/features/terminal';
import { UiIcon, type UiIconName } from '~/src/shared/ui';
import { useSidebarStore } from '~/src/widgets/terminal/store';

const changeChartType = useChangeChartType();
const currentType = useChartType();

const buttons = computed<
	{
		text: string;
		icon: UiIconName;
		type: ChartType;
	}[]
>(() => [
	{
		text: 'Область',
		icon: 'filled--chart--area',
		type: 'area',
	},
	{
		text: 'Линия',
		icon: 'filled--chart--line',
		type: 'line',
	},
	{
		text: 'Японские свечи',
		icon: 'filled--chart--candlestick',
		type: 'candlestick',
	},
	{
		text: 'Бары',
		icon: 'filled--chart--bars',
		type: 'bars',
	},
]);
const sidebarStore = useSidebarStore();
const onChange = (type: ChartType) => {
	sidebarStore.closeDrawer();
	changeChartType(type);
};
</script>

<template>
	<div class="flex flex-col gap-4">
		<BaseButton
			v-for="button in buttons"
			:key="button.icon"
			color="alternative-dark"
			size="sm"
			outline
			filled-icon
			:button-text="button.text"
			:class="{
				'!bg-gray-700 !text-white': currentType === button.type,
			}"
			@click="onChange(button.type)"
		>
			<template #left>
				<UiIcon
					:name="button.icon"
					class="text-[16px] text-white"
				/>
			</template>
		</BaseButton>
	</div>
</template>
